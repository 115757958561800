<template>
  <section class="ulsemo-admin" v-if="render_admin">
    <!-- TODO add project icons via img_src prop -->
    <div class="ulsemo-admin__top">
      <admin_corner_icon v-if="!user_info || show_other_admin_components"/>
      <language_selector
        v-if="(!user_info || show_other_admin_components) && selected_language"
        :admin_selected_language="selected_language"
        @language_changed="update_language"
      />
    </div>
    <div v-if="loading" class="admin-loader">
      <Loader />
      <div class="admin-loader__message" v-html="loading_message" />
    </div>

    <div v-if="!user_info" class="log-in-page">
      <Login :user_in_admin="true" :language="selected_language"/>
    </div>

    <user_store_selection
      v-else-if="!show_other_admin_components && is_global_admin && user_info && !selected_project && !fetching_store"
      @add_message="add_message"
    />

    <router-view
      v-else-if="show_other_admin_components"
      name="other_admin_components"
    />

    <AdminComponent
      v-else-if="is_global_admin ? (selected_project && user_info && permissions.user) : user_info"
      :selected_language="selected_language"
    />

    <md-snackbar
      md-position="center"
      :md-active.sync="show_message"
      :md-duration="(message.message || '').includes('email_not_verified') ? 15000 : message_timeout"
      :class="{
        'md-alert': !message.severity || message.severity === severities.DANGER,
        'md-success': message.severity === severities.SUCCESS
      }"
    >
      <div>
        {{ $translate(message.message) }}
        <md-button
          v-if="(message.message || '').includes('email_not_verified')"
          class="md-primary"
          @click="resend_verification_email"
        >{{ $translate("login.resend_verification_email") }}
        </md-button>
      </div>
    </md-snackbar>
  </section>
</template>

<script>
import Vue from "vue"
import { mapActions, mapMutations, mapState } from "vuex"
import VueMaterial from "vue-material"
import Loader from "@/applications/Shared/components/loader/index.vue"
import { ADMIN, ADMIN_STORE, severities, USER_STORE } from "../../constants/others_constants"
import { default_language, message_durations, SHARED_STORE, translated_languages } from "../../../Shared/constants/other"
import admin_corner_icon from "../utils/admin_corner_icon"
import translator from  "../../../Shared/translator"
import language_selector from "../../../Marketplace/components/utils/language_selector"
import { update_admin_language, user_admin_language, user_admin_store_id } from "@/data/other_constants"
import { ADMIN_LOGIN } from "../../../Shared/stores/Shared/constants"
import is_user_in_global_admin from "../../../Shared/methods/is_user_in_global_admin"
import { event_hub } from "@/main"
import { UPDATE_ADMIN_LANGUAGE } from "../../stores/Admin/constants"
import { GUIDE, BILLING } from "../../../../data/page_types"

const user_store_selection = () => import("../utils/user_store_selection")
const AdminComponent = () => import("../App")
const Login = () => import("../../../Shared/components/Login")

Vue.use(VueMaterial)

export default {
  components: {
    AdminComponent,
    admin_corner_icon,
    user_store_selection,
    Login,
    language_selector,
    Loader
  },
  data() {
    return {
      email: "",
      password: "",
      forgot_password_email: "",
      stay_signed_in: false,
      show_message: false,
      open_password_modal: false,
      fetching_store: false,
      render_admin: true,
      message: {},
      message_timeout: message_durations.medium,
      selected_language: "",
      fetched_project: null,
      severities,
    }
  },
  computed: {
    ...mapState(ADMIN_STORE, [
      "loading",
      "loading_message",
    ]),
    ...mapState(USER_STORE, ["selected_project", "permissions", "user_info", "projects"]),
    ...mapState(SHARED_STORE, ["is_global_admin", "user_credentials"]),
    show_user_guide() {
      return !this.projects?.length && this.is_global_admin && this.user_info
    },
    show_other_admin_components() {
      const is_billing_page = this.$route.path.includes(`/${BILLING}`)

      return Boolean(this.show_user_guide || is_billing_page)
    },
  },
  watch: {
    user_info() {
      if (!this.selected_project && !this.fetched_project && is_user_in_global_admin()) this.load_store()
    },
    show_user_guide(val) {
      if (val) this.$router.push({ path: `/${GUIDE}/step_1` })
    },
  },
  async created() {
    document.body.style.padding = "0"

    this.inject_material_styles_into_header()
    this.update_favicon_to_ulsemo()
    this.hotjar_init_func()
    if (!is_user_in_global_admin()) this.fetched_project = true

    this.update_language(this.$route.query.lang || window.localStorage.getItem(user_admin_language) || default_language)

    event_hub.$on(update_admin_language, language => {
      this.update_language(language)
    })
  },
  mounted() {
    if (this.show_user_guide) this.$router.push({ path: `/${GUIDE}/step_1` })
  },
  methods: {
    ...mapActions(SHARED_STORE, {
      admin_login: ADMIN_LOGIN,
    }),
    ...mapMutations(ADMIN, {
      update_admin_store_language: UPDATE_ADMIN_LANGUAGE
    }),
    async hotjar_init_func() {
        const hotjar_stable_version = 6
        const hotjar_id = 2374179

        window.hj = window.hj || function() {
          (window.hj.q = window.hj.q || []).push(arguments)
        }
        window._hjSettings = {
          hjid: hotjar_id,
          hjsv: hotjar_stable_version
        }

        try {
          await this.$loadScript(`https://static.hotjar.com/c/hotjar-${hotjar_id}.js?sv=${hotjar_stable_version}`)
        } catch (error) {
          console.log(error)
        }
    },
    async load_store() {
      const last_visited_store = window.localStorage.getItem(user_admin_store_id) === "undefined" ?
        null : window.localStorage.getItem(user_admin_store_id)
      const store_id = this.$route.params.store_id || last_visited_store

      if (this.user_info && store_id && this.projects.some(({ project_id }) => project_id === store_id)) {
        this.fetching_store = true
        await this.admin_login({ project_id: store_id })
        this.update_language(this.user_info.language_code)
        this.fetched_project = true

        this.$router.push(({
          path: this.$route.fullPath.includes(store_id) ? this.$route.fullPath : `/admin/${store_id}`
        }))
        this.fetching_store = false
      }
    },
    add_message(message) {
      this.message = message
      this.show_message = true

      setTimeout(() => this.show_message = false, this.message_timeout);
    },
    inject_material_styles_into_header() {
      [
        "https://unpkg.com/vue-material@beta/dist/theme/default.css",
        "https://unpkg.com/vue-material@beta/dist/vue-material.min.css",
        "https://fonts.googleapis.com/css?family=Roboto:400,500,700,400italic|Material+Icons"
      ].forEach(route => {
        const link_el = document.createElement("link")

        link_el.setAttribute("rel", "stylesheet")
        link_el.setAttribute("href", route)

        document.head.appendChild(link_el);
      })
    },
    async update_language(language) {
      if (!language || language === this.selected_language) return
      if (!translated_languages.includes(language)) language = default_language

      this.selected_language = language
      window.localStorage.setItem(
        user_admin_language,
        language
      )
      this.render_admin = false

      this.update_admin_store_language(language)

      await translator(false, language)

      this.$nextTick(() => this.render_admin = true)
    },
    update_favicon_to_ulsemo() {
      const link = document.createElement("link")
      link.type = "image/x-icon"
      link.rel = "shortcut icon"
      link.href = "/static/icons/ulsemo-icon.svg"

      document.head.appendChild(link)
    },
  }
}
</script>

<style lang="scss">
@use "../../../../styles/_global-constants" as *;
@use "../../../../styles/_admin-constants" as *;
@use "../../../../styles/admin";

  .ulsemo-admin {
    min-height: 100vh;
    color: $pure-black;
    background: $pure-white;

    h1 {
      display: block;
      width: 100%;
      padding: 0 20px;
      margin: 30px 0 0;
      line-height: normal;
    }

    h2 {
      line-height: normal;
    }

    &__options {
      width: 50px;
    }

    &__top {
      display: flex;
      justify-content: space-between;
    }

    .marketplace-language-selector {
      $language-selector-size: 53px;

      width: $language-selector-size;
      height: $language-selector-size;
      background: $pure-white;
      display: flex;
      justify-content: center;
      border-radius: $border-radius;

      &__selected {
        padding: $half-default-size;

        img {
          width: 50px !important;
          height: 100% !important;
        }
      }

      span {
        display: none;
      }

      &__options {
        top: 100%;
        right: 0;
        width: $language-selector-size;
      }
    }
  }

  .md-dialog {
    color: $pure-black;
    background: $pure-white;
    border-radius: $border-radius !important;
    z-index: $z-index--top !important;
  }

  .log-in-page {
    padding: $default-size;

    h2 {
      color: var(--font_heavy);
      text-align: center;
    }

    &__form {
      padding: $default-size $half-default-size;
      margin: auto;
    }

    &__reset-email {
      padding: $default-size;
    }
  }

  @media (max-width: 600px) {
    .md-dialog-fullscreen {
      position: relative !important;
    }
  }
</style>
